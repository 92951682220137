@use 'global/variable' as v;

@keyframes shrink {
  0% {
    opacity: 0;
    scale: 9;
  }
  30% {
    opacity: 1;
    scale: 1;
  }
  38% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    background-color: rgba(v.$whitesmoke, 1);
  }
  100% {
    background-color: rgba(v.$whitesmoke, 0);
  }
}

@keyframes switch {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
    scale: 1.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes leftToRight {
  from {
    translate: 0 0 0;
  }
  to {
    translate: -100% 0 0;
  }
}
