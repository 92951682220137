@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-button {
  display: block;
  width: 100%;
  max-width: 350px;
  padding: 23px;
  font-size: var(--18px);
  text-align: center;
  color: v.$white;
  background-color: v.$black;
  border: 1px solid transparent;
  transition: color v.$anime, background-color v.$anime, border v.$anime;
  position: relative;

  @include m.sp {
    max-width: 35rem;
    font-size: 1.8rem;
  }

  &_arrow {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    right: 23px;
  }
}

.c-button:hover {
  @include m.pc {
    color: v.$black;
    background-color: v.$white;
    border: 1px solid v.$black;
  }

  .c-button_arrow {
    .cls-2 {
      fill: v.$black;
    }
  }
}
