@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-recruit {
  &_image {
    width: 100%;

    @include m.pc {
      height: 660px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &_content {
    padding: 64px 0;

    @include m.pc {
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50% 0;
    }

    @include m.sp {
      padding: 4.8rem 0;
      background-color: v.$whitesmoke;
    }

    &Inner {
      @include m.pc {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }

  &_text {
    @include m.pc {
      color: v.$white;
    }
  }

  &_text {
    margin: 40px 0 0;

    @include m.sp {
      margin: 2.4rem 0 0;
    }
  }

  &_button {
    margin: 0 auto;

    @include m.sp {
      margin: 3.2rem 0 0;
    }
  }
}
