@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  @for $i from 1 through 48 {
    --#{$i}px: calc(#{$i}rem / 10);
  }
  font-size: 10px;

  @include m.sp {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: v.$NotoSerifJP;
  color: v.$black;

  @include m.pc {
    margin: v.$headerHeight 0 0;
  }

  &.is-active {
    overflow: hidden;
  }
}

.pc {
  @include m.pc {
    display: block !important;
  }

  @include m.sp {
    display: none !important;
  }
}

.sp {
  @include m.pc {
    display: none !important;
  }

  @include m.sp {
    display: block !important;
  }
}

.pc-flex {
  @include m.pc {
    display: flex !important;
  }

  @include m.sp {
    display: none !important;
  }
}

.sp-flex {
  @include m.pc {
    display: none !important;
  }

  @include m.sp {
    display: flex !important;
  }
}
