@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-image {
  &_540 {
    width: 100%;
    max-width: 540px;

    @include m.sp {
      max-width: 54rem;
    }
  }
}
