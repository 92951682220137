.u-flx {
  &Ai {
    &-ce {
      align-items: center;
    }
  }

  &Jc {
    &-ce {
      justify-content: center;
    }
  }

  &Wr {
    &-ap {
      flex-wrap: wrap;
    }
  }

  &Fd {
    &-rr {
      flex-direction: row-reverse;
    }
  }
}
