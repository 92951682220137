@charset "UTF-8";

$NotoSerifJP: 'Noto Serif JP', serif;

$black: #212121;
$silver: #cccccc;
$whitesmoke: #f9f9f9;
$white: #ffffff;

$anime: ease 0.3s;

// hero and header
$headerHeight: 84px;
$headerHeightSP: 5.6rem;

$frontNewsHeight: 72px;

$artBoard: 1440px;
