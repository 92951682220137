@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-container {
  display: flex;

  &--blk {
    @include m.sp {
      display: block;
    }
  }

  &_di {
    width: 50%;
  }

  &_item {
    width: 100%;
  }
}
