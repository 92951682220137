@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-front {
  &News {
    padding: 24px 0;
    background-color: v.$whitesmoke;

    @include m.pc {
      height: v.$frontNewsHeight;
    }

    @include m.sp {
      padding: 1.6rem 0;
    }

    &_wrap {
      flex: 1;
      overflow: hidden;

      @include m.pc {
        border-right: 2px solid v.$silver;
      }
    }

    &_container,
    &_item {
      & + & {
        margin-left: 24px;

        @include m.sp {
          margin-left: 2.4rem;
        }
      }
    }

    &_container {
      flex: 1;
      white-space: nowrap;
      animation: leftToRight 20s infinite linear 0.5s both;
    }

    &_button {
      padding: 0 32px;
      text-align: center;

      @include m.sp {
        padding: 0;
        margin: 1.6rem 0 0;
      }
    }

    &_link {
      @include m.sp {
        margin: 0 auto;
      }
    }
  }

  &_box {
    gap: 32px;

    @include m.sp {
      gap: 1.6rem;
    }

    &_image {
      width: calc(50% - 16px);

      @include m.sp {
        width: calc(50% - 0.8rem);
      }
    }
  }

  &Service {
    @include m.pc {
      position: relative;
      z-index: 1;
    }
  }

  &_right {
    margin: 0 0 0 64px;

    @include m.sp {
      margin: 6.4rem 0 0;
    }
  }

  &_left {
    margin: 0 48px 0 0;
    text-align: right;

    @include m.sp {
      margin: 4.8rem 0 0;
      text-align: left;
    }
  }
}
