@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 1 through 15 {
  .u-mt-#{$i * 8} {
    margin-top: #{$i * 8}px;

    @include m.sp {
      @if $i > 1 {
        margin-top: #{($i * 0.1 - 0.1) * 8}rem;
      } @else {
        margin-top: #{$i * 8 * 0.5}rem;
      }
    }
  }
}

//  16 * 8
.u-mt-128 {
  margin-top: 128px;

  @include m.sp {
    margin-top: 8.8rem;
  }
}
