@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-link {
  font-size: var(--16px);
  line-height: calc(23 / 16);
  transition: opacity v.$anime;

  @include m.arrow;

  @include m.sp {
    font-size: 1.6rem;
  }

  &:hover {
    @include m.pc {
      opacity: 0.7;
    }
  }
}
