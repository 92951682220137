@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-contact {
  &Ordinary {
    &_frame {
      padding: 64px;
      border: 1px solid v.$silver;
      transition: border v.$anime;

      @include m.sp {
        padding: 2.4rem;
      }

      &:hover {
        @include m.pc {
          border: 3px solid v.$silver;
        }
      }
    }

    &_sentence {
      margin: 0 0 0 80px;

      @include m.sp {
        margin: 2.4rem 0 0;
      }
    }

    &_text {
      & + & {
        margin: 16px 0 0;

        @include m.sp {
          margin: 1.6rem 0 0;
        }
      }
    }

    &_box {
      margin: 32px 0 0;
      font-size: var(--16px);
      line-height: calc(23 / 16);

      @include m.sp {
        margin: 3.2rem 0 0;
        font-size: 1.6rem;
      }
    }

    &_arrow {
      margin-left: 0.4rem;

      @include m.arrow;
    }
  }
}
