@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-scrollable {
  &_image {
    width: 400px;

    @include m.sp {
      width: 20rem;
    }
  }

  &_margin {
    margin: 56px calc(50% - 50vw) 0 0;
    overflow-x: scroll;
  }

  &_list {
    width: 100%;
    max-width: 400px;

    @include m.sp {
      max-width: 56rem;
    }
  }

  &_sentence {
    padding: 0 64px 24px 0;

    @include m.sp {
      padding: 0 2.4rem 1.6rem 0;
    }
  }
}
