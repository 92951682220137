@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-icon {
  width: 100%;
  max-width: 18px;

  @include m.sp {
    max-width: 1.8rem;
  }
}
