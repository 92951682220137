@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: v.$headerHeight;
  padding: 0 32px;
  background-color: v.$white;
  position: fixed;
  left: 0;
  z-index: 5;

  @include m.pc {
    top: 0;
    transition: box-shadow v.$anime;
  }

  @include m.tab {
    padding: 0 8px;
  }

  @include m.sp {
    width: calc(100% - v.$headerHeightSP);
    height: v.$headerHeightSP;
    padding: 0 0 0 1.6rem;
    top: auto;
    bottom: 0;
  }

  &--baglog {
    @include m.sp {
      width: calc(100% - v.$headerHeightSP);
      height: v.$headerHeightSP;
      padding: 0 0 0 1.6rem;
      top: auto;
      bottom: auto;
    }
  }

  &.is-active {
    @include m.pc {
      box-shadow: 0 25px 20px -20px hsl(200 50% 20% / 40%);
    }
  }

  &_logo {
    width: 100%;
    max-width: 220px;
    transition: opacity v.$anime;

    @include m.tab {
      max-width: 140px;
    }

    @include m.sp {
      max-width: 12rem;
    }

    &:hover {
      @include m.pc {
        opacity: 0.7;
      }
    }
  }

  &Contact {
    &_tel,
    &_mail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: v.$headerHeightSP;
      height: v.$headerHeightSP;
      background-color: v.$black;
    }
  }

  &Trigger {
    width: v.$headerHeightSP;
    height: v.$headerHeightSP;
    background-color: v.$white;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;

    &--baglog {
      @include m.sp {
        right: -5.6rem;
      }
    }

    &_line {
      width: 2rem;
      height: 2px;
      background-color: v.$black;
      position: absolute;
      left: 50%;
      translate: -50% 0 0;

      &:nth-of-type(1) {
        top: 3.3rem;
        transition: rotate ease 0.3s, top ease 0.3s;
      }

      &:nth-of-type(2) {
        top: calc(50% - 1px);
        transition: opacity ease 0.3s;
      }

      &:nth-of-type(3) {
        bottom: 3.3rem;
        transition: rotate ease 0.3s, bottom ease 0.3s;
      }
    }
  }

  &_nav {
    @include m.sp {
      position: fixed;
    }
  }

  &Outer {
    display: flex;
    align-items: center;
    height: v.$headerHeight;

    @include m.sp {
      display: block;
      width: 100%;
      height: calc(100dvh - v.$headerHeightSP);
      padding: 4rem;
      background-color: v.$black;
      position: fixed;
      top: 0;
      left: 0;
      translate: 100% 0 0;
      transition: translate v.$anime;
      overflow-y: scroll;
    }

    &--baglog {
      @include m.sp {
        display: block;
        width: calc(100% + 5.6rem);
        height: calc(100dvh - v.$headerHeightSP);
        padding: 4rem;
        background-color: v.$black;
        position: fixed;
        top: 5.6rem;
        left: 0;
        translate: 100% 0 0;
        transition: translate v.$anime;
        overflow-y: scroll;
      }
    }

    &.is-active {
      translate: 0 0 0;
      transition: translate v.$anime;
    }

    &_list {
      display: flex;
      align-items: center;
      transition: color v.$anime, background-color v.$anime;
      cursor: pointer;

      @include m.pc {
        height: 100%;
        padding: 0 16px;
        line-height: v.$headerHeight;
      }

      @include m.sp {
        display: block;
      }

      & + & {
        @include m.sp {
          margin-top: 3.2rem;
        }
      }

      &:hover {
        @include m.pc {
          color: v.$white;
          background-color: v.$black;
        }
      }
    }

    &_arrow {
      @include m.sp {
        @include m.arrow(white);
      }
    }

    &_text,
    &_link {
      font-size: var(--16px);
      line-height: calc(23 / 16);

      @include m.tab {
        font-size: var(--14px);
      }

      @include m.sp {
        font-size: 1.6rem;
        color: v.$white;
      }
    }

    &_unit {
      @include m.sp {
        margin-top: 3.2rem;
      }
    }
  }

  &Inner {
    display: flex;
    width: 100%;
    height: 0;
    background-color: v.$black;
    opacity: 0;
    visibility: hidden;
    transition: height v.$anime, opacity v.$anime, box-shadow v.$anime;

    @include m.pc {
      justify-content: flex-end;
      padding-right: 17%;
      position: absolute;
      top: v.$headerHeight;
      left: 0;
      z-index: 1;
    }

    @include m.sp {
      display: block;
      height: auto;
      padding: 1.6rem 0 0 1.6rem;
      opacity: 1;
      visibility: visible;
    }

    &.is-active {
      @include m.pc {
        box-shadow: 0 25px 20px -20px hsl(200 50% 20% / 40%);
      }
    }

    &_list {
      color: v.$white;
      transition: color v.$anime, background-color v.$anime;

      @include m.pc {
        padding: 0 16px;
      }

      & + & {
        @include m.sp {
          margin-top: 0.8rem;
        }
      }

      &:hover {
        @include m.pc {
          color: v.$black;
          background-color: v.$whitesmoke;
        }
      }
    }

    &_link {
      font-size: var(--14px);

      @include m.pc {
        width: 100%;
        height: 100%;
        display: block;
        line-height: v.$headerHeight;
      }

      @include m.sp {
        font-size: 1.4rem;
        line-height: calc(20 / 14);
        @include m.arrow(white, 1.3rem);
      }

      &--larger {
        font-size: var(--26px);

        @include m.sp {
          font-size: 2.6rem;
          line-height: calc(37 / 26);
        }
      }
    }
  }

  &_button {
    display: block;
    padding: 8px 16px;
    font-size: var(--16px);
    line-height: calc(23 / 16);
    color: v.$white;
    background-color: v.$black;
    border: 1px solid transparent;
    transition: color v.$anime, background-color v.$anime;

    @include m.tab {
      font-size: var(--14px);
    }

    @include m.sp {
      padding: 1.6rem;
      font-size: 1.6rem;
      text-align: center;
      color: v.$black;
      background-color: v.$white;
      position: relative;
    }

    &Arrow {
      @include m.pc {
        display: none;
      }

      @include m.sp {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        right: 1.6rem;
      }
    }
  }

  &_language {
    @include m.pc {
      position: relative;
      overflow: hidden;
    }

    @include m.sp {
      display: flex !important;
    }

    &Head {
      transition: translate v.$anime, opacity v.$anime;

      @include m.sp {
        padding: 0 2.4rem;
      }
    }

    &Tail {
      @include m.pc {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: calc(100% + 4px) -50% 0;
        transition: translate v.$anime;
      }

      @include m.sp {
        padding: 0 2.4rem;
        border-left: 1px solid v.$white;
      }
    }
  }

  &_18 {
    font-size: var(--18px);
    line-height: calc(26 / 18);

    @include m.sp {
      font-size: 1.8rem;
    }
  }
}

.l-headerOuter_list:hover {
  &.l-header_language {
    > .l-header_languageHead {
      @include m.pc {
        opacity: 0;
        translate: calc(-100% - 16px) 0 0;
        pointer-events: none;
      }
    }

    > .l-header_languageTail {
      @include m.pc {
        translate: -50% -50% 0;
      }
    }
  }

  .l-headerOuter_link {
    path {
      @include m.pc {
        transition: fill v.$anime;
        fill: v.$white;
      }
    }
  }

  .l-headerInner {
    @include m.pc {
      height: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}

.l-headerOuter_unit:hover {
  > .l-header_button {
    @include m.pc {
      color: v.$black;
      background-color: v.$white;
      border: 1px solid v.$black;
    }
  }
}

.l-headerTrigger.is-active {
  > .l-headerTrigger_line {
    &:nth-of-type(1) {
      top: 2.7rem;
      rotate: 45deg;
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      bottom: 2.7rem;
      rotate: -45deg;
    }
  }
}
