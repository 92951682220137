@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  padding: 64px 0;
  background: v.$whitesmoke;
  color: v.$black;

  @include m.sp {
    padding: 5.6rem 0 13.5rem;
  }

  &_logo {
    margin: 0 auto;
  }

  &_heading {
    margin: 15px auto 0;
    font-size: var(--16px);
    line-height: calc(28 / 16);
    text-align: center;

    @include m.sp {
      font-size: 1.6rem;
      margin: 1.5rem auto 0;
    }
  }

  &Sub {
    margin: 50px 0 0;
    text-align: center;

    @include m.sp {
      margin: 5rem 0 0;
    }

    &_link {
      transition: opacity v.$anime;

      &:hover {
        @include m.pc {
          opacity: 0.7;
        }
      }
    }
  }

  &_copy {
    display: block;
    margin: 30px 0 0;
    font-size: 12px;
    line-height: calc(17 / 12);
    text-align: center;

    @include m.sp {
      font-size: 1.2rem;
      margin: 3rem 0 0;
    }
  }

  &Outer {
    display: flex;
    justify-content: space-between;

    @include m.sp {
      display: block;
      width: 100%;
      max-width: 24rem; // logoと同じ幅にする
      margin: auto;
    }

    &_list {
      & + & {
        @include m.sp {
          margin-top: 3rem;
        }
      }
    }

    &_text,
    &_link {
      font-size: var(--16px);
      line-height: calc(23 / 16);

      @include m.sp {
        font-size: 1.6rem;
      }
    }

    &_link {
      display: flex;
      align-items: center;
      transition: opacity v.$anime;

      &::after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        margin-left: 8px;
        background: url('../img/common/icon_linkarrow.svg') no-repeat center / contain;
      }

      &:hover {
        @include m.pc {
          opacity: 0.7;
        }
      }
    }
  }

  &Inner {
    padding: 15px 0 0 15px;

    @include m.sp {
      padding: 1.5rem 0 0 1.5rem;
    }

    &_link {
      display: flex;
      align-items: center;
      transition: opacity v.$anime;

      &::after {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        margin-left: 8px;
        background: url('../img/common/icon_linkarrow.svg') no-repeat center / contain;
      }

      &:hover {
        @include m.pc {
          opacity: 0.7;
        }
      }
    }
  }

  &Contact {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 350px;
    background-color: v.$whitesmoke;
    box-shadow: 0 3px 16px rgba(v.$black, 0.2);
    position: fixed;
    bottom: 20px;
    right: 0;
    z-index: 5;
    opacity: 0;
    translate: 0 200px 0;
    transition: opacity v.$anime, translate v.$anime;

    &.is-active {
      @include m.pc {
        opacity: 1;
        translate: 0 0 0;
      }
    }

    @include m.sp {
      display: none;
    }

    &_tel {
      padding: 8px 24px;
      flex: 1;
      cursor: pointer;

      @include m.pc {
        @include m.hoverOpacity;
      }

      &Link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--24px);
      }

      &Text {
        font-size: var(--14px);
        line-height: calc(20 / 14);
        text-align: center;
      }
    }

    &_mail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      background-color: v.$black;

      @include m.pc {
        @include m.hoverOpacity;
      }
    }
  }
}
