.u-fw {
  &-thin {
    font-weight: 100;
  }

  &-extraLight {
    font-weight: 200;
  }

  &-light {
    font-weight: 300;
  }

  &-normal {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-semiBold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-extraBold {
    font-weight: 800;
  }

  &-black {
    font-weight: 900;
  }
}
