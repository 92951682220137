@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-text {
  &_14 {
    font-size: var(--14px);
    line-height: calc(24 / 14);

    @include m.sp {
      font-size: 1.4rem;
    }
  }

  &_16 {
    font-size: var(--16px);
    line-height: calc(28 / 16);

    @include m.sp {
      font-size: 1.4rem;
      line-height: calc(24 / 14);
    }
  }

  &_18 {
    font-size: var(--18px);
    line-height: calc(32 / 18);

    @include m.sp {
      font-size: 1.6rem;
      line-height: calc(28 / 16);
    }
  }
}
