@use 'global/mixin' as m;
@use 'global/variable' as v;

@for $i from 1 through 15 {
  .u-pb-#{$i * 8} {
    padding-bottom: #{$i * 8}px;

    @include m.sp {
      @if $i > 1 {
        padding-bottom: #{($i * 0.1 - 0.1) * 8}rem;
      } @else {
        padding-bottom: #{$i * 8 * 0.5}rem;
      }
    }
  }
}

//  16 * 8
.u-pb-128 {
  padding-bottom: 128px;

  @include m.sp {
    padding-bottom: 8.8rem;
  }
}
