@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-title {
  font-size: var(--36px);
  line-height: calc(64 / 36);

  @include m.sp {
    font-size: 2.2rem;
    line-height: calc(39 / 22);
  }

  &--small {
    font-size: var(--22px);
    line-height: calc(32 / 22);

    @include m.sp {
      font-size: 2rem;
      line-height: calc(35 / 20);
    }
  }

  &Image {
    img {
      width: 100%;
    }

    &--service {
      max-width: 136px;
    }

    &--recruit {
      max-width: 138px;
    }

    &--contact {
      max-width: 143px;
    }

    &--company {
      max-width: 174px;
    }
  }
}
