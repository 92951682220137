@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin arrow($color: black, $width: 18px, $ml: 8px) {
  display: flex;
  align-items: center;
  width: fit-content;

  &::after {
    content: '';
    display: block;
    width: $width;
    height: $width;
    margin-left: $ml;
    background: url('../img/common/icon_linkarrow_' + #{$color} + '.svg') no-repeat center / contain;
  }
}

@mixin hoverOpacity {
  transition: opacity ease 0.3s;

  &:hover {
    @include pc {
      opacity: 0.7;
    }
  }
}
