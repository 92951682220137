@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  width: 100%;
  margin: 0 auto;

  @include m.pc {
    max-width: 1140px;
    padding: 0 20px;
  }

  @include m.sp {
    width: 90%;
  }

  &--end {
    @include m.pc {
      max-width: v.$artBoard;
      padding: 0 20px;
    }
  }

  &--scrollable {
    @media screen and (min-width: 768px) and (max-width: 1180px) {
      padding: 0;
    }

    @include m.sp {
      width: 100%;
    }
  }
}
