@use 'global/mixin' as m;
@use 'global/variable' as v;
@use 'global/keyframes' as k;

.p-hero {
  width: 100%;
  height: calc(100dvh - v.$headerHeight - v.$frontNewsHeight);
  background-color: v.$whitesmoke;
  position: relative;
  overflow: hidden;

  @include m.sp {
    height: calc(100dvh - v.$headerHeightSP);
  }

  &_wrap {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &_fragment {
    width: 100%;
    height: 100dvh;
    position: absolute;
    top: -#{v.$headerHeight};
    left: 0;
  }

  &_set {
    width: 100%;
    min-width: v.$artBoard;

    @include m.pc {
      height: 100dvh;
    }

    @include m.sp {
      min-width: 200vw;
    }
  }

  &_bag {
    width: calc(100% / 7);
    height: calc(100% / 5);

    img {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    @for $i from 1 through 10 {
      &.is-hidden-#{$i} {
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(v.$whitesmoke, 1);
          position: absolute;
          top: 0;
          left: 0;
          animation: disappear 2.5s forwards;
          animation-delay: calc(#{$i} * 0.2s + 2.5s);
        }
      }
    }
  }

  &_image,
  &_switch {
    width: 100%;

    @include m.sp {
      height: calc(100dvh - v.$headerHeightSP);
    }
  }

  &_image {
    height: 100dvh;
    animation: shrink 10s forwards;
  }

  &_switch {
    height: 100%;
    opacity: 0;
    animation: appear 10s 6s forwards;
  }

  &_list {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    animation: switch 36s 0s infinite;

    &:nth-child(2) {
      animation-delay: 6s;
    }

    &:nth-child(3) {
      animation-delay: 12s;
    }

    &:nth-child(4) {
      animation-delay: 18s;
    }

    &:nth-child(5) {
      animation-delay: 24s;
    }

    &:nth-child(6) {
      animation-delay: 30s;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &_center {
    width: 28%;
    max-width: 400px;
    position: absolute;
    top: calc(50% - v.$headerHeight);
    left: 50%;
    translate: -50% calc(-50% + (v.$headerHeight/ 2)) 0;
    opacity: 0;
    animation: appear 4s 6s forwards;

    @include m.sp {
      width: 50%;
      max-width: 27rem;
    }
  }
}
