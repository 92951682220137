@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-logo {
  display: block;
  width: 100%;
  max-width: 220px;

  @include m.sp {
    max-width: 22rem;
  }
}
