@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-gallery {
  @include m.pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 32px;
  }

  @include m.sp {
    display: flex;
  }

  &_wrap {
    @include m.sp {
      margin-right: calc(50% - 50vw);
      overflow: hidden;
      overflow-x: scroll;
      white-space: nowrap;
    }
  }

  &_item {
    width: 100%;

    @include m.sp {
      height: 15rem;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:nth-child(1) {
      @include m.pc {
        width: calc(60% - (32px / 2));
      }

      @include m.sp {
        min-width: 19rem;
      }
    }

    &:nth-child(2) {
      @include m.pc {
        width: calc(40% - (32px / 2));
      }

      @include m.sp {
        min-width: 12rem;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      @include m.pc {
        width: calc((100% - 32px) / 2);
      }

      @include m.sp {
        min-width: 24rem;
      }
    }
  }
}
